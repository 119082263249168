import { Grid, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Typewriter from 'typewriter-effect';
import Layout from '../components/layout';
import SocialIcons from '../components/socialIcons';
import { aboutImage } from './index.module.css';

const AboutPage = () => {
  return (
    <Layout pageTitle="About Me | EtherMagellan" pageDescription="A little insight as to who Ether Magellan is." size="md">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} display="flex" flexDirection="column" alignItems="center">
          <StaticImage className={aboutImage} src="../images/ethermagellan.png" />
          <Typography variant="h5">
            <Typewriter
              options={{ autoStart: true, loop: true }}
              onInit={(typewriter) => {
                typewriter
                  .typeString('AUTOMATION EXPERT')
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString('FULLSTACK DEVELOPER')
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString('ROBOTICS ENGINEER')
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString('GENERATIVE ARTIST')
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString('WEB3 EXPLORER')
                  .pauseFor(1500)
                  .deleteAll()
                  .start();
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="space-between">
          {/* <div>
            Welcome! I'm <span style={{ letterSpacing: '-2px' }}>&#9608;&#9608;&#9608;&#9608;</span> , but I'm better known as Magellan. I'm
            a Black Robotics Engineer with too many passions to count, but automation is <i>always</i> at the heart of everything I do. I'm
            bringing my technical expertise to the Web3 space to help others develop their ideas from concept to reality. I'm always working
            on something new, but check out some of my other pages to see if there is anything I can help you with! Feel free to reach out
            to me on twitter or discord with business inquiries.
          </div> */}
          <div>
            Welcome! I'm Steph, but I'm better known as my online persona Magellan. I'm a Black Robotics Engineer with too many passions to
            count, but automation is <i>always</i> at the heart of everything I do. I'm bringing my technical expertise to the Web3 space to
            help others develop their ideas from concept to reality. I'm always working on something new, but check out some of my other
            pages to see if there is anything I can help you with! Feel free to reach out to me on twitter or discord with business
            inquiries.
          </div>
          <SocialIcons />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default AboutPage;
